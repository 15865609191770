import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AttendancesPerMonth from '../components/AttendancesPerMonth/AttendancesPerMonth';
import ButtonCard from '../components/ButtonCard';
import QualitativeEvaluations from '../components/QualitativeEvaluations/QualitativeEvaluations';

import SuccessfulTutorships from '../components/SuccessfulTutorships/SuccessfulTutorships';
import TutorshipsPerMonth from '../components/TutorshipsPerMonth/TutorshipsPerMonth';

import {
  attendancesPerMonthSelector,
  successfulTutorshipsSelector,
  tutorshipsPerMonthSelector,
  studentsFeedbackSelector,
  availableYearsSelector
} from '../tutorshipStatisticsSlice';
import { fetchAvailableYearsTutorshipStatistics, fetchTutorshipStatistics } from '../tutorshipStatisticsThunks';

import './TutorshipStatistics.scss';
import { useEffect, useState } from 'react';

function omitFromObject(x, attributes) {
  const result = {};
  Object.entries(x).forEach(([key, value]) => {
    if (attributes.some((att) => key === att)) return;
    result[key] = value;
  });
  return result;
}

const TutorshipStatistics = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  const availableYears = useSelector(availableYearsSelector);

  const successfulTutorships = useSelector(successfulTutorshipsSelector);
  const tutorshipsPerMonth = useSelector(tutorshipsPerMonthSelector);
  const attendancesPerMonth = useSelector(attendancesPerMonthSelector);
  const studentsFeedback = useSelector(studentsFeedbackSelector);

  const [year, setYear] = useState(new Date().getFullYear());

  const formatStudentsFeedback = useCallback((value) => {
    if (!value) return;
    value = omitFromObject(value, ['averageSessionRating', 'averageTutorRating', 'numberOfTutorshipsWithRating']);
    return Object.fromEntries(
      Object.entries(value).map(([subject, data]) => {
        return [
          subject,
          {
            categories: [
              {
                value: data.averageSessionRating,
                percentage: `${(data.averageSessionRating / 5) * 100}%`,
              },
              {
                value: data.averageTutorRating,
                percentage: `${(data.averageTutorRating / 5) * 100}%`,
              },
            ],

            comments: data['feedback'].map((feedback) => ({
              headerData: [
                {
                  label: <i className="fa fa-star text-warning"></i>,
                  value: feedback.sessionRating,
                },
                {
                  label: <i className="fa fa-graduation-cap text-primary"></i>,
                  value: feedback.tutorRating,
                },
              ],
              content: feedback.comments,
            })),
          },
        ];
      })
    );
  }, []);

  useEffect(() => {
    dispatch(fetchAvailableYearsTutorshipStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (availableYears === undefined || availableYears === null) {
      return;
    }
    setYear(availableYears[0]);
  }, [availableYears]);

  useEffect(() => {
    if (year === undefined || year === null) {
      return;
    }
    dispatch(fetchTutorshipStatistics(year));
  }, [dispatch, year]);

  return (
    <div className={`statistics-dashboard-main-container container-fluid`}>
      {/* Page Heading */}
      <div className="row align-items-center justify-content-between mb-4 w-100 py-3 mx-auto">
        <h1 className="h3 text-gray-800 col-sm-6 p-0">Estadísticas</h1>

        <div className="d-flex align-items-center col-sm-6 p-0 justify-content-end">
          <label className="text-white text-center text-lg font-weight-bold mb-0 mr-3">Año:</label>
          <select
            defaultValue={year}
            onChange={(e) => setYear(parseInt(e.target.value))}
            className="year-select custom-select custom-select-lg w-75"
          >
            {
              (availableYears !== undefined && availableYears !== null) ?
                availableYears.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))
                :
                <></>
            }
          </select>
        </div>
      </div>

      {/* Content Row */}
      <div className="row">
        <ButtonCard icon={<i className="fas fa-calendar fa-2x text-success"></i>} title="Tutorías" color="success" />

        <ButtonCard
          icon={<i className="fas fa-star fa-2x text-warning"></i>}
          title="Promedio de Valoraciones Generales"
          data={studentsFeedback !== null ? studentsFeedback['averageSessionRating'] : 'Sin datos'}
          color="warning"
        />
      </div>

      {/* Content Row */}

      <div className="row">
        <div className="col-12">
          <SuccessfulTutorships
            total={successfulTutorships?.numberOfTotalSuccessfulTutorships}
            data={omitFromObject(successfulTutorships || {}, ['numberOfTotalSuccessfulTutorships'])}
          />

          <TutorshipsPerMonth data={tutorshipsPerMonth} />

          <AttendancesPerMonth data={attendancesPerMonth} />

          <QualitativeEvaluations
            categories={[
              {
                icon: 'star',
                label: 'Puntaje de la sesión',
                description: `es la valoración promedio para las tutorías de esta materia
                                acorde a los estudiantes.`,
                color: 'warning',
              },
              {
                icon: 'graduation-cap',
                label: 'Puntaje del tutor',
                description: `es la valoración promedio de los tutores para esta materia
                                acorde a los estudiantes.`,
                color: 'primary',
              },
            ]}
            data={formatStudentsFeedback(studentsFeedback)}
          />
        </div>
      </div>
    </div>
  );
};

export default TutorshipStatistics;
