import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/img/logo.png';
import { showAlert } from '../../common/alertService';
import { subjectsSelector } from '../../subjects/subjectSlice';
import { fetchSubjects } from '../../subjects/subjectThunk';
import { tutorsSelector } from '../../tutors/tutorSlice';
import { fetchTutors } from '../../tutors/tutorThunks';
import { fetchTutorshipById, updateTutorshipData } from '../../tutorship/tutorshipThunks';
import {
  cleanupUpdateTutorShip,
  currentTutorshipSelector,
  tutorshipErrorSelector,
  tutorshipSelector,
} from '../../tutorship/tutorshipSlice';
import { tutorshipStatusSelector } from '../../common/tutorshipStatus/tutorshipStatusSlice';
import { fetchTutorshipStatus } from '../../common/tutorshipStatus/tutorshipStatusThunk';

import './EditTutorship.scss';

const EditTutorship = () => {
  const CURRENT_DATE = new Date();
  const MIN_ALLOW_DATE = CURRENT_DATE.toISOString().slice(0, 10) + 'T00:00';

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subjects = useSelector(subjectsSelector);
  const tutors = useSelector(tutorsSelector);
  const tutorshipStatus = useSelector(tutorshipStatusSelector);
  const tutorshipData = useSelector(currentTutorshipSelector);
  const errorMessage = useSelector(tutorshipErrorSelector);
  const successMessage = useSelector(tutorshipSelector);

  const [tutorId, setTutor] = useState(tutorshipData?.tutorId);
  const [newDate, setNewDate] = useState();
  const [subjectId, setSubject] = useState(tutorshipData?.subjectId);
  const [statusId, setStatus] = useState(tutorshipData?.tutorConfirmationStatusId);
  const { id } = useParams();

  useEffect(() => {
    dispatch(cleanupUpdateTutorShip());

    return () => {
      dispatch(cleanupUpdateTutorShip());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchTutorshipById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (subjects === null) {
      dispatch(fetchSubjects());
    }
    if (tutors === null) {
      dispatch(fetchTutors());
    }
    if (tutorshipStatus === null) {
      dispatch(fetchTutorshipStatus());
    }
    if (tutorshipData) {
      setSubject(tutorshipData.subjectId);
      setTutor(tutorshipData.tutorId);
      setStatus(tutorshipData.tutorConfirmationStatusId);
    }
  }, [subjects, tutors, tutorshipStatus, tutorshipData, dispatch]);

  function onTutorChange(event) {
    setTutor(event.target.value);
  }

  function onSubjectChange(event) {
    setSubject(event.target.value);
  }

  function onNewDateChange(event) {
    setNewDate(event.target.value);
  }

  function onStatusChange(event) {
    setStatus(event.target.value);
  }

  const formatDate = (date, addHours = 0) => {
    const dateFormat = new Date(date);
    dateFormat.setHours(dateFormat.getHours() + addHours);
    return `${dateFormat.getFullYear()}-${String(dateFormat.getMonth() + 1).padStart(2, '0')}-${String(
      dateFormat.getDate()
    ).padStart(2, '0')}T${String(dateFormat.getHours()).padStart(2, '0')}:${String(dateFormat.getMinutes()).padStart(
      2,
      '0'
    )}:${String(dateFormat.getSeconds()).padStart(2, '0')}`;
  };

  const sendUpdatedTutorship = (event) => {
    event.preventDefault();

    const date = formatDate(newDate);
    const endDate = formatDate(newDate, 2);

    if (!tutorId || !newDate || !subjectId || !statusId) {
      showAlert({ icon: 'error', title: 'Oops...', text: 'No pueden haber campos vacíos' });
      return;
    }

    const newTutorshipData = {
      id,
      date,
      endDate,
      tutorId,
      subjectId,
      statusId,
    };
    dispatch(updateTutorshipData(newTutorshipData));
  };

  useEffect(() => {
    if (successMessage) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'Tutoría editada éxitosamente',
      }).then(function () {
        dispatch(cleanupUpdateTutorShip());
        navigate('/tutorias/lista');
      });
    }

    if (errorMessage) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorMessage });
    }
  }, [successMessage, errorMessage, navigate, dispatch]);

  if (subjects === null || tutors === null || tutorshipStatus === null) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="editTutorshipContainer">
        <section className="d-flex justify-content-center">
          <form className="w-75 mx-auto p-4 mt-4 rounded shadow bg-white" onSubmit={sendUpdatedTutorship}>
            <h1 className="title mb-5" id="editTutorshipTitle">
              Editar Tutoría
            </h1>
            <div className="form-group">
              <label htmlFor="subjects">Materias</label>
              <select className="form-control" id="subjects" value={subjectId} onChange={onSubjectChange}>
                {subjects?.map((subject) => (
                  <option value={subject.id} key={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="newDate">Nueva fecha</label>
              <input
                type="datetime-local"
                className="form-control"
                id="newDate"
                value={newDate}
                onChange={onNewDateChange}
                min={MIN_ALLOW_DATE}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tutor">Tutores</label>
              <select className="form-control" id="tutor" value={tutorId} onChange={onTutorChange}>
                {tutors?.map((tutor) => (
                  <option value={tutor.id} key={tutor.id}>
                    {tutor.user.fullName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status">Estado</label>
              <select className="form-control" id="status" value={statusId} onChange={onStatusChange}>
                {tutorshipStatus?.map((status) => (
                  <option value={status.id} key={status.id}>
                    {status.statusName}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button type="submit" className="btn btn-success btn-ms shadow-sm">
                Editar tutoría
              </button>
            </div>
          </form>
        </section>
        <div className="d-flex justify-content-end w-75 mx-auto">
          <Link to="/tutorias/lista" className="btn mt-3 shadow-sm bg-white">
            Volver
          </Link>
        </div>
        <div className="justify-content-center w-75 mx-auto">
          <p id="editTutorshipText" className="text-center mt-4">
            Recuerda que gracias a ti los niveles de deserción disminuyen y los sueños aumentan
          </p>
          <img className="mx-auto d-block editTutorshipLogo" src={Logo} alt="Logo ATV" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditTutorship;
