// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframeContainer {
  margin: -1.5rem;
}

.tutorshipListTitle {
  width: 100%;
  height: 70px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tutorshipListTitle h1 {
  color: #3058c9;
  font-weight: bolder;
}

.iframeTutorshipList {
  width: 100%;
  height: 100vh;
  overflow: auto;
  border: none;
  margin-top: -67px;
}`, "",{"version":3,"sources":["webpack://./src/features/admin/tutorship/list/TutorshipList.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".iframeContainer{\n    margin: -1.5rem;\n}\n\n.tutorshipListTitle {\n  width: 100%;\n  height: 70px;\n  background-color: #f5f5f5;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n}\n\n.tutorshipListTitle h1 {\n  color: #3058c9;\n  font-weight: bolder;\n}\n\n.iframeTutorshipList {\n  width: 100%;\n  height: 100vh;\n  overflow: auto;\n  border: none;\n  margin-top: -67px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
