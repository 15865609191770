import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSuccessMessage,
  userAllInfoSelector,
  userErrorSelector,
  userSelector,
  userSuccessSelector,
} from '../../users/userSlice';
import { subjectsSelector } from '../../subjects/subjectSlice';
import { fetchSubjects } from '../../subjects/subjectThunk';
import { fetchUserById, updateUser } from '../../users/userThunks';
import { registerOrUpdateTutor, fetchAllTutorInfoById } from '../tutorThunks';
import { resetTutorState, tutorErrorSelector, tutorSelector } from '../tutorSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { showAlert } from '../../common/alertService';
import { currentTutorSelector } from '../tutorSlice';
import SessionTypeEnum from '../../../Enums/SessionTypeEnum';

import './RegisterTutor.scss';
import { WORK_DAYS_OF_THE_WEEK, BLOCK_TIME_RANGES } from '../../common/constants';
import { Roles } from '../../users/roles/roles';
import { disabledTestersButtons } from '../../functionalities/functions';

const RegisterTutor = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const subjects = useSelector(subjectsSelector);
  const user = useSelector(userSelector);
  const userInfo = useSelector(userAllInfoSelector);
  const successMessage = useSelector(userSuccessSelector);
  const errorMessage = useSelector(userErrorSelector);
  const errorMessageOnRegister = useSelector(tutorErrorSelector);
  const tutorData = useSelector(currentTutorSelector);
  const updatedTutorData = useSelector(tutorSelector);

  /** @type {[string|undefined, function]} */
  const [cellphoneNumber, setCellphoneNumber] = useState('');

  /** @type {[number|undefined, function]} */
  const [academicLevel, setAcademicLevel] = useState();

  /** @type {[number[], function]} */
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);

  /** @type {[number[], function]} */
  const [selectedSessionTypeIds, setSelectedSessionTypeIds] = useState([]);

  /** @type {[any[], function]} */
  const [selectedAvailabilityTimes, setSelectedAvailabilityTimes] = useState([]);

  const cellPhoneChange = (event) => {
    setCellphoneNumber(event.target.value);
  };

  const sessionTypeChange = (event) => {
    const { value, checked } = event.target;
    const newSessionTypeId = Number(value);

    if (checked) {
      setSelectedSessionTypeIds((previousSessionTypeIds) => [...previousSessionTypeIds, newSessionTypeId]);
    } else {
      setSelectedSessionTypeIds((previousSessionTypeIds) => {
        return previousSessionTypeIds.filter((sessionTypeId) => sessionTypeId !== newSessionTypeId);
      });
    }
  };

  useEffect(() => {
    if (userInfo?.studentData.academicLevel !== undefined) {
      setAcademicLevel(userInfo.studentData.academicLevel);
    }
  }, [userInfo]);

  const onAcademicLevelChange = (event) => {
    const newAcademicLevel = Math.max(1, Math.min(10, Number(event.target.value)));
    setAcademicLevel(newAcademicLevel);
  };

  function getDate() {
    const currentDate = new Date();
    return currentDate.toISOString().slice(0, 10);
  }

  function getFinalDate(startDate) {
    const finalDate = new Date(startDate);
    finalDate.setFullYear(finalDate.getFullYear() + 1);
    return finalDate.toISOString().slice(0, 10);
  }

  useEffect(() => {
    const currentId = id ? parseInt(id) : user.id;
    if (id) {
      dispatch(fetchAllTutorInfoById(currentId));
    }
    dispatch(fetchUserById(currentId));
  }, [dispatch, id, user.id]);

  useEffect(() => {
    if (!tutorData) {
      return;
    }
    setSelectedSubjectIds(tutorData.subjects.map((subject) => subject?.id));
    setSelectedSessionTypeIds(tutorData.sessionTypeIds);
    setSelectedAvailabilityTimes(tutorData.availability);
  }, [tutorData, dispatch]);

  useEffect(() => {
    if (subjects === null) {
      dispatch(fetchSubjects());
    }
  }, [subjects, dispatch]);

  const toggleTimeRangeSelection = (weekDayNumber, timeRange) => {
    if (!checkIfTimeRangeIsSelected(weekDayNumber, timeRange)) {
      setSelectedAvailabilityTimes([
        ...selectedAvailabilityTimes,
        {
          dayOfWeek: weekDayNumber,
          blockStartTime: timeRange.start,
          blockEndTime: timeRange.end,
        },
      ]);
      return;
    }
    setSelectedAvailabilityTimes(
      selectedAvailabilityTimes.filter(
        (availabilityTimes) =>
          availabilityTimes.dayOfWeek !== weekDayNumber ||
          availabilityTimes.blockStartTime !== timeRange.start ||
          availabilityTimes.blockEndTime !== timeRange.end
      )
    );
  };

  const toggleSubjectSelection = (subjectId) => {
    if (selectedSubjectIds.includes(subjectId)) {
      setSelectedSubjectIds((previousSubjectIds) => previousSubjectIds.filter((id) => id !== subjectId));
    } else {
      setSelectedSubjectIds((previousSubjectIds) => [...previousSubjectIds, subjectId]);
    }
  };

  const updateUserInformation = (event) => {
    event.preventDefault();

    const userInformation = {
      id: id ? parseInt(id) : user.id,
      studentId: userInfo.studentData.id ?? user.studentId,
      academicLevel,
    };
    dispatch(updateUser(userInformation));
  };

  useEffect(() => {
    const currentId = id ? parseInt(id) : user.id;
    if (successMessage) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: successMessage,
      }).then(() => {
        dispatch(fetchUserById(currentId));
        dispatch(clearSuccessMessage());
      });
    }
    if (errorMessage) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorMessage });
    }
  }, [dispatch, successMessage, errorMessage, navigate]);

  const checkIfTimeRangeIsSelected = (weekDayNumber, timeRange) => {
    return selectedAvailabilityTimes?.some(
      (availabilityTimes) =>
        availabilityTimes.dayOfWeek === weekDayNumber &&
        availabilityTimes.blockStartTime === timeRange.start &&
        availabilityTimes.blockEndTime === timeRange.end
    );
  };

  const registerTutor = (e) => {
    e.preventDefault();

    if (selectedSubjectIds.length <= 0 || selectedSessionTypeIds.length <= 0 || selectedAvailabilityTimes.length <= 0) {
      return showAlert({
        icon: 'error',
        title: 'No pueden haber campos sin llenar',
        text: `No has seleccionado las estrategias en que deseas participar o
        no has seleccionado ninguna materia o indicado tu disponibiilidad`,
      });
    }

    const startDate = tutorData?.startDate || getDate();
    const finalDate = tutorData?.finalDate || getFinalDate(startDate);

    const tutorInformation = {
      id: id ? parseInt(id) : user.id,
      subjectIds: selectedSubjectIds,
      sessionTypeIds: selectedSessionTypeIds,
      startDate,
      finalDate,
      availability: selectedAvailabilityTimes,
    };
    dispatch(registerOrUpdateTutor(tutorInformation));
  };

  useEffect(() => {
    if (errorMessageOnRegister) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: errorMessageOnRegister,
      }).then(() => {
        if (!userInfo || (id && !tutorData)) {
          navigate('/');
        }
      });
    }
    if (user.role === Roles.STUDENT) {
      if (updatedTutorData) {
        showAlert({
          icon: 'success',
          title: 'Éxito',
          text: `Te invitamos a estar pendiente, desde el equipo social de 
          Antivirus nos estaremos contactando contigo para continuar con el proceso`,
        }).then(() => {
          dispatch(resetTutorState());
          navigate('/');
        });
      }
    } else {
      if (updatedTutorData) {
        showAlert({
          icon: 'success',
          title: 'Éxito',
          text: 'Información actualizada con éxito',
        }).then(() => {
          dispatch(resetTutorState());
          navigate('/');
        });
      }
    }
  }, [updatedTutorData, errorMessageOnRegister, user.role, dispatch, id, tutorData, userInfo, navigate]);

  const handleReset = () => {
    setSelectedAvailabilityTimes([]);
    setCellphoneNumber('');
    setAcademicLevel();
    setSelectedSubjectIds([]);
    setSelectedSessionTypeIds([]);
  };

  if (!userInfo || (id && !tutorData)) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      {user.role !== Roles.TUTOR && (
        <>
          <h1 className="h3 mb-2 text-gray-800">Conviértete en tutor ¡Ayúdanos a ayudar!</h1>
          <p className="mb-4">
            Cada vez más personas reconocen en ti un profesional en formación muy teso, hay muchos estudiantes esperando
            para ser acompañados <br></br> ¡Únete a nuestra red de tutores y sé un referente en el aprendizaje de otros!
          </p>
        </>
      )}

      <div className="card shadow mb-4">
        <div className="card-header">
          <h6 className="font-weight-bold">Actualiza y confirma tus datos</h6>
        </div>
        <div className="card-body">
          <form onSubmit={updateUserInformation}>
            <div className="row ">
              <div className="form-group col-12">
                <label htmlFor="email" className="form-label">
                  Se te notificará a este correo
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm "
                  readOnly
                  id="email"
                  value={userInfo?.email || user.email}
                  disabled
                />
              </div>
              <div className="form-group col-sm-8">
                <label htmlFor="select-modality" className="form-label">
                  Número de celular
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="celular"
                  readOnly
                  value={cellphoneNumber || userInfo?.phoneNumber}
                  onChange={cellPhoneChange}
                />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="academicLevel" className="form-label">
                  Nivel académico
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="academicLevel"
                  value={academicLevel !== undefined ? academicLevel : ''}
                  onChange={onAcademicLevelChange}
                />
              </div>
              {user.role !== Roles.TUTOR && (
                <div className="col-sm-8">
                  <p>
                    <b>Nota: </b>
                    Con este proceso te estás inscribiendo como tutor, recuerda que juntos somos más. Te invitamos a
                    estar pendiente de tu correo electrónico y número de celular donde recibirás toda la información
                    necesaria para ser parte de nuestra red de tutores.
                    <br />
                    ¡Gracias por querer compartir tus saberes e inspirar a más personas!
                  </p>
                </div>
              )}
              {user.role === Roles.TUTOR && (
                <div className="col-sm-8">
                  <p>¡Gracias por compartir tus saberes e inspirar a más personas!</p>
                </div>
              )}
              <div className="col-sm-4 d-flex align-items-end justify-content-end">
                <button
                  disabled={disabledTestersButtons(user.documentNumber)}
                  className="btn btn-success"
                  type="submit"
                >
                  Actualizar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {userInfo?.studentData.academicLevel === null && user.role !== Roles.ADMIN ? (
        <h6 className="header-message">
          Por favor, ingresa tu nivel académico y haz clic en 'Actualizar' para guardar los cambios.
        </h6>
      ) : (
        <form onSubmit={registerTutor}>
          <div className="card shadow mb-4">
            <div className="card-header">
              <h6 className="font-weight-bold ">Cuéntanos en cuál o cuáles materias eres el más teso</h6>
              <small>
                Selecciona las materias que sacan todo tu potencial, esas en las que te sientes full preparado para
                compartir tu conocimiento{' '}
              </small>
            </div>

            <div className="card-body">
              <div className="row">
                {subjects?.map((materia, idx) => (
                  <div key={idx} className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-check">
                      <input
                        id={'subject-' + idx}
                        className="form-check-input mr-1"
                        type="checkbox"
                        value={materia.id}
                        onChange={(e) => {
                          toggleSubjectSelection(materia.id);
                        }}
                        checked={selectedSubjectIds.includes(materia.id)}
                      />
                      <label htmlFor={'subject-' + idx} className="form-check-label">
                        {materia.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="font-weight-bold">Disponibilidad horaria</h6>
              <small>Cuéntanos tu disponibilidad para que juntos acompañemos cada vez, a más estudiantes </small>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table" border={1}>
                  <thead>
                    <tr className="text-center">
                      <th scope="col" className="day-column">
                        Lunes
                      </th>
                      <th scope="col" className="day-column">
                        Martes
                      </th>
                      <th scope="col" className="day-column">
                        Miércoles
                      </th>
                      <th scope="col" className="day-column">
                        Jueves
                      </th>
                      <th scope="col" className="day-column">
                        Viernes
                      </th>
                      <th scope="col" className="day-column">
                        Sábado
                      </th>
                      <th scope="col" className="day-column">
                        Domingo
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {BLOCK_TIME_RANGES.map((timeRange, index) => (
                      <tr key={index}>
                        {WORK_DAYS_OF_THE_WEEK.map((_dayName, dayIndex) => (
                          <td key={`${index}-${dayIndex}`}>
                            <label className="checkedOptionsLabel">
                              <input
                                className="form-check-input mr-2  checkedInput"
                                type="checkbox"
                                onChange={(e) => {
                                  toggleTimeRangeSelection(dayIndex + 1, timeRange);
                                }}
                                checked={checkIfTimeRangeIsSelected(dayIndex + 1, timeRange)}
                              />
                              {timeRange.label}
                            </label>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="font-weight-bold">¿Te gustaría participar en otras estrategias de Antivirus?</h6>
              <p className="mb-0">Selecciona en cuál o cuáles (Debes seleccionar al menos una)</p>
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className=" row ">
                  <div className="col-lg-4 tutor-type-checkbox">
                    <label>
                      <input
                        className="form-check-input mr-2"
                        onChange={sessionTypeChange}
                        type="checkbox"
                        value={SessionTypeEnum.TUTORSHIPS}
                        checked={selectedSessionTypeIds.includes(SessionTypeEnum.TUTORSHIPS)}
                      />
                      Tutorías personalizadas
                    </label>
                  </div>
                  <div className="col-lg-4 tutor-type-checkbox">
                    <label>
                      <input
                        className="form-check-input mr-2"
                        onChange={sessionTypeChange}
                        type="checkbox"
                        value={SessionTypeEnum.STUDY_GROUPS}
                        checked={selectedSessionTypeIds.includes(SessionTypeEnum.STUDY_GROUPS)}
                      />{' '}
                      Grupos de estudio
                    </label>
                  </div>
                  <div className="col-lg-4 tutor-type-checkbox">
                    <label>
                      <input
                        className="form-check-input mr-2"
                        onChange={sessionTypeChange}
                        type="checkbox"
                        value={SessionTypeEnum.WORKSHOPS}
                        checked={selectedSessionTypeIds.includes(SessionTypeEnum.WORKSHOPS)}
                      />
                      Nivélate con Antivirus (talleres grupales)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center ">
            <Link className="btn btn-danger m-1" to="/">
              VOLVER
            </Link>
            <button className="btn btn-warning m-1" type="button" onClick={handleReset}>
              LIMPIAR
            </button>
            <button
              disabled={disabledTestersButtons(user.documentNumber)}
              className="btn btn-success m-1"
              type="submit"
            >
              GUARDAR
            </button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default RegisterTutor;
