import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TutorManagement.scss';
import TutorStatusEnum from '../../../Enums/TutorStatusEnum';
import SessionTypeEnum from '../../../Enums/SessionTypeEnum';
import { tutorsSelector } from '../tutorSlice';
import { fetchTutors, updateTutor, removeTutor } from '../tutorThunks';
import { useNavigate } from 'react-router-dom';
import { disabledTestersButtons } from '../../functionalities/functions';
import { userSelector } from '../../users/userSlice';

const TutorManagement = () => {
  const normalizeText = (text) => {
    return text
      ? text
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      : '';
  };

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** @type {any[]?} */
  const tutors = useSelector(tutorsSelector);
  const [needToLoadTutors, setNeedToLoadTutors] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const user = useSelector(userSelector);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const updateTutorStatus = async (tutor, status) => {
    if (!tutors) {
      return;
    }
    let tutorToUpdate = { ...tutor };

    if (tutorToUpdate) {
      tutorToUpdate.status = status;
    }

    const validUpdateStatuses = [TutorStatusEnum.ACTIVE, TutorStatusEnum.REJECTED, TutorStatusEnum.DISABLED];

    if (validUpdateStatuses.includes(status)) {
      await dispatch(updateTutor(tutorToUpdate));
    } else if (status === TutorStatusEnum.INACTIVE) {
      await dispatch(removeTutor(tutorToUpdate.id));
    }

    await setNeedToLoadTutors(true);
  };

  function printSubjects(subjects = []) {
    if (subjects.length === 0) {
      return 'Sin materias registradas';
    }
    return subjects.map((subject) => subject?.name).join(', ');
  }

  const printSessionTypeNames = (sessionTypeIds = []) => {
    if (sessionTypeIds.length === 0) {
      return 'Sin registrar';
    }

    const sessionNames = {
      [SessionTypeEnum.TUTORSHIPS]: 'Tutorías',
      [SessionTypeEnum.STUDY_GROUPS]: 'Grupos de Estudio',
      [SessionTypeEnum.WORKSHOPS]: 'Talleres',
    };

    const sessionTypeNames = sessionTypeIds.map((sessionType) => sessionNames[sessionType]);

    return sessionTypeNames.join(', ');
  };

  useEffect(() => {
    if (needToLoadTutors) {
      dispatch(fetchTutors());
      setNeedToLoadTutors(false);
    }
  }, [dispatch, needToLoadTutors]);

  const filteredTutors = tutors
    ?.filter((tutor) => {
      const tutorFullName = (tutor.user?.fullName || '').replace(/\s+/g, ' ').trim();
      const statusName = (tutor.statusData?.name || '').replace(/\s+/g, ' ').trim();
      const subjectName = Array.isArray(tutor.subjects)
        ? tutor.subjects.map((subject) => subject.name || '').join(', ')
        : String(tutor.subjects || '').trim();

      return (
        normalizeText(tutorFullName).includes(normalizeText(searchTerm)) ||
        normalizeText(subjectName).includes(normalizeText(searchTerm)) ||
        normalizeText(statusName).includes(normalizeText(searchTerm))
      );
    })
    .sort((a, b) => a.status - b.status);

  return (
    <>
      <h1 className="h3 mb-2 text-gray-800">Lista de candidatos a tutorías</h1>
      <p className="mb-4">A continuación encontrarás el estado en que se encuentran los tutores</p>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Tutores en espera de aprobación</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Materia</th>
                  <th>Estrategias</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Nombre</th>
                  <th>Materia</th>
                  <th>Estrategias</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </tfoot>
              <tbody>
                {tutors?.map(
                  (tutor) =>
                    tutor.status === TutorStatusEnum.INACTIVE && (
                      <tr
                        key={tutor.id}
                        onClick={() => navigate(`/tutores/detalle/${tutor.id}`)}
                        className="clickable-row"
                      >
                        <td className="text-capitalize">{tutor.user?.fullName}</td>
                        <td>{printSubjects(tutor.subjects)}</td>
                        <td>{printSessionTypeNames(tutor.sessionTypeIds)}</td>
                        <td>Pendiente por aprobación</td>
                        <td className="g-1">
                          <i
                            onClick={(event) => {
                              if (disabledTestersButtons(user.documentNumber)) {
                                return;
                              }
                              event.stopPropagation();
                              updateTutorStatus(tutor, TutorStatusEnum.ACTIVE);
                            }}
                            title="Aceptar tutor"
                            className="fas fa-circle-check fa-2x btn-check p-1"
                          ></i>
                          <i
                            onClick={(event) => {
                              if (disabledTestersButtons(user.documentNumber)) {
                                return;
                              }
                              event.stopPropagation();
                              updateTutorStatus(tutor, TutorStatusEnum.REJECTED);
                            }}
                            title="Rechazar tutor"
                            className="fas fa-circle-xmark fa-2x btn-reject p-1"
                          ></i>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header py-3 header-tutorship-history">
          <h6 className="m-0 p-2 font-weight-bold text-primary">Tutores</h6>
          <div className="d-flex">
            <input
              value={searchTerm}
              onChange={handleSearch}
              type="text"
              placeholder="Buscar por nombre, materia o estado..."
              className="form-control me-2 mr-2"
              style={{ width: '310px' }}
            />
            <button className="btn btn-primary" onClick={() => setSearchTerm('')}>
              Limpiar
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Materia</th>
                  <th>Estrategias</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Materia</th>
                  <th>Estrategias</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </tfoot>
              <tbody>
                {filteredTutors?.map(
                  (tutor, index) =>
                    tutor.status !== TutorStatusEnum.INACTIVE && (
                      <tr
                        key={tutor.id}
                        onClick={() => navigate(`/tutores/detalle/${tutor.id}`)}
                        className="clickable-row"
                      >
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{tutor.user?.fullName}</td>
                        <td>{printSubjects(tutor.subjects)}</td>
                        <td>{printSessionTypeNames(tutor.sessionTypeIds)}</td>
                        {tutor.status === TutorStatusEnum.DISABLED || tutor.status === TutorStatusEnum.REJECTED ? (
                          <td className="text-capitalize text-danger">
                            <i className="fa fa-warning mr-1"></i>
                            {tutor.statusData?.name}
                          </td>
                        ) : (
                          <td className="text-capitalize text-success">
                            <i className="fa fa-check mr-1"></i>
                            {tutor.statusData?.name}
                          </td>
                        )}

                        <td className="g-1">
                          {tutor.status === TutorStatusEnum.DISABLED || tutor.status === TutorStatusEnum.REJECTED ? (
                            <i
                              onClick={(event) => {
                                if (disabledTestersButtons(user.documentNumber)) {
                                  return;
                                }
                                event.stopPropagation();
                                return updateTutorStatus(tutor, TutorStatusEnum.ACTIVE);
                              }}
                              title="Habilitar Tutor"
                              className="fas fa-circle-check fa-2x  btn-check p-1"
                            ></i>
                          ) : (
                            <i
                              onClick={(event) => {
                                if (disabledTestersButtons(user.documentNumber)) {
                                  return;
                                }
                                event.stopPropagation();
                                return updateTutorStatus(tutor, TutorStatusEnum.DISABLED);
                              }}
                              title="Deshabilitar Tutor"
                              className="fas fa-circle-xmark fa-2x btn-reject p-1"
                            ></i>
                          )}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorManagement;
