const SpecialUserEnum = {
  ADMIN: [
    '11111000001A',
    '11111000002A',
    '11111000003A',
    '11111000004A',
    '11111000005A',
    '11111000001E',
    '11111000002E',
    '11111000003E',
    '11111000004E',
    '11111000005E',
  ],
};
export default SpecialUserEnum;
