import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableYearsTutorshipStatistics, fetchTutorshipStatistics } from "./tutorshipStatisticsThunks";

const initialState = {
    isLoading: false,
    /** @type {string | null} */
    error: null,
    successfulTutorships: null,
    tutorshipsPerMonth: null,
    attendancesPerMonth: null,
    studentsFeedback: null,
    availableYears: null
};

export const tutorshipStatisticsSlice = createSlice({
    name: 'tutorshipStatistics',
    initialState,
    reducers: {
        resetTutorshipStatisticsState: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTutorshipStatistics.pending, (state, _action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTutorshipStatistics.fulfilled, (state, action) => {
                state.isLoading = false;
                const {
                    successfulTutorships,
                    tutorshipsPerMonth,
                    attendancesPerMonth,
                    studentsFeedback
                } = action.payload;
                state.successfulTutorships = successfulTutorships;
                state.tutorshipsPerMonth = tutorshipsPerMonth;
                state.attendancesPerMonth = attendancesPerMonth;
                state.studentsFeedback = studentsFeedback;
            })
            .addCase(fetchTutorshipStatistics.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            })
            .addCase(fetchAvailableYearsTutorshipStatistics.pending, (state, _action) => {
              state.isLoading = true;
              state.error = null;
            })
            .addCase(fetchAvailableYearsTutorshipStatistics.fulfilled, (state, action) => {
              state.isLoading = false;
              state.availableYears = action.payload.availableYears;
            })
            .addCase(fetchAvailableYearsTutorshipStatistics.rejected, (state, action) => {
              state.isLoading = false;
              state.error = action.error.message || null;
            });
    }
});

export const { resetTutorshipStatisticsState } = tutorshipStatisticsSlice.actions;

export const availableYearsSelector = (state) => state.tutorshipStatistics.availableYears;

export const successfulTutorshipsSelector = (state) => state.tutorshipStatistics.successfulTutorships;
export const tutorshipsPerMonthSelector = (state) => state.tutorshipStatistics.tutorshipsPerMonth;
export const attendancesPerMonthSelector = (state) => state.tutorshipStatistics.attendancesPerMonth;
export const studentsFeedbackSelector = (state) => state.tutorshipStatistics.studentsFeedback;
