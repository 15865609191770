import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupReports, reportErrorSelector, reportSelector } from '../../../common/reports/reportsSlice';
import { fetchTutorshipsReport } from '../../../common/reports/reportsThunk';
import { showAlert } from '../../../common/alertService';

import './TutorshipList.scss';
import { userSelector } from '../../../users/userSlice';
import { Roles } from '../../../users/roles/roles';

const TutorshipList = () => {
  const ADMIN_MODULE = process.env.REACT_APP_ADMIN_APP_URL + '/tutorias/tutorias-historial-tutor';

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  /** @type {import('react').MutableRefObject<?HTMLAnchorElement>} */
  const downloadLink = useRef(null);

  const csvToExport = useSelector(reportSelector);
  const errorExportReport = useSelector(reportErrorSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (downloadLink && downloadLink.current) {
      downloadLink.current.click();
      dispatch(cleanupReports());
    }
  }, [csvToExport, dispatch]);

  const downloadReports = () => {
    dispatch(fetchTutorshipsReport());
  };

  useEffect(() => {
    if (errorExportReport) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorExportReport });
      return;
    }
  }, [errorExportReport]);

  return (
    <div className="iframeContainer">
      {csvToExport && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a href={URL.createObjectURL(csvToExport)} ref={downloadLink} download="Reporte Linea Académica.csv"></a>
      )}
      <div className="tutorshipListTitle">
        <h1>Historial de tutorías</h1>
        {user.role === Roles.ADMIN && (
          <button type="submit" className="btn btn-success" onClick={downloadReports}>
            Exportar reporte de tutorías
          </button>
        )}
      </div>
      <iframe className="iframeTutorshipList" title="Página de administración de tutorías" src={ADMIN_MODULE} />
    </div>
  );
};

export default TutorshipList;
