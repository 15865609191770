const TutorshipStatusEnum = {
  PENDING: 0,
  SCHEDULED: 1,
  RESCHEDULED: 2,
  COMPLETED: 3,
  NONATTENDANCE: 4,
  CANCELED: 5,
  REJECTED: 6,
  NULLIFIED: 7,
};

export default TutorshipStatusEnum;
