// Vendors
import 'bootstrap/dist/js/bootstrap';
import React from 'react';
import { useSelector } from 'react-redux';

// Styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

// Components
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './features/users/login/Login';
import SelectTutorRegisterForm from './features/tutors/selectRegisterFrom/SelectTutorRegisterForm.jsx';
import TutorManagement from './features/tutors/tutorManagement/TutorManagement.jsx';
import RegisterPassword from './features/users/registerPassword/RegisterPassword';
import StudyGroupRequestForm from './features/studyGroups/studyGroupRequestForm/StudyGroupRequestForm';
import CreateStudyGroup from './features/studyGroups/create/CreateStudyGroup';
import DashboardLayout from './layouts/Dashboard/DashboardLayout.jsx';
import { userSelector } from './features/users/userSlice';
import { Roles } from './features/users/roles/roles';
import Auth from './features/users/roles/Auth';
import Register from './features/users/register/Register.jsx';
import EditTutorship from './features/admin/tutorship/EditTutorship.jsx';
import RegisterTutor from './features/tutors/register/RegisterTutor.jsx';
import TutorToStudentRating from './features/tutorship/tutorToStudentRating/TutorToStudentRating.jsx';
import TutorDetails from './features/tutors/tutorDetails/TutorDetails.jsx';
import TutorshipStatistics from './features/statistics/tutorshipStatistics/TutorshipStatistics.jsx';
import StudentEditInformation from './features/student/StudentEditInformation.jsx';
import PasswordRecovery from './features/users/passwordRecovery/PasswordRecovery.jsx';
import TutorshipsHistory from './features/tutorship/managment/TutorshipHistory.jsx';

const App = () => {
  const user = useSelector(userSelector);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          <Route
            index
            Component={() => {
              const studentAppRoles = [Roles.STUDENT, Roles.TUTOR];
              if (user && process.env.REACT_APP_STUDENTS_APP_URL && studentAppRoles.includes(user.role)) {
                window.location.replace(process.env.REACT_APP_STUDENTS_APP_URL);
                return null;
              }

              if (user?.role === Roles.WORKSHOP_STUDENT) {
                // TODO: Add a page to redirect non Computer Science (Systems and Informatics Engineering) students
                return <Navigate to="/ser-tutor" />;
              }

              if (user?.role === Roles.ADMIN) {
                return <Navigate to="/tutorias/estadisticas" />;
              }

              return <Navigate to="/login" />;
            }}
          />

          <Route element={<Auth allowedRoles={[Roles.ADMIN]} />}>
            <Route path="/ser-tutor/elige-tipo" element={<SelectTutorRegisterForm />} />
          </Route>

          <Route path="/tutores">
            <Route element={<Auth allowedRoles={[Roles.ADMIN]} />}>
              <Route path="" element={<TutorManagement />} />
              <Route path="detalle/:id" element={<TutorDetails />} />
            </Route>
            <Route element={<Auth allowedRoles={[Roles.TUTOR, Roles.ADMIN]} />}>
              <Route path="editar/:id" element={<RegisterTutor />} />
            </Route>
          </Route>

          <Route path="/grupos-de-estudio">
            <Route element={<Auth allowedRoles={[Roles.ADMIN]} />}>
              <Route path="crear" element={<CreateStudyGroup />} />
            </Route>
            <Route
              element={
                <Auth allowedRoles={[Roles.STUDENT, Roles.WORKSHOP_STUDENT, Roles.TUTOR, Roles.STUDY_GROUP_TUTOR]} />
              }
            >
              <Route path="peticion" element={<StudyGroupRequestForm />} />
            </Route>
          </Route>
          <Route element={<Auth allowedRoles={[Roles.STUDENT, Roles.WORKSHOP_STUDENT]} />}>
            <Route
              path="ser-tutor"
              element={user?.itHasTutor && user?.role === Roles.STUDENT ? <Navigate to="/" /> : <RegisterTutor />}
            />
          </Route>
          <Route element={<Auth allowedRoles={[Roles.STUDENT]} />}>
            <Route path="editar" element={<StudentEditInformation />} />
          </Route>
          <Route path="/tutorias" element={<Auth allowedRoles={[Roles.ADMIN, Roles.TUTOR]} />}>
            <Route path="lista" element={<TutorshipsHistory />} />
            <Route element={<Auth allowedRoles={[Roles.ADMIN]} />}>
              <Route path="editar/:id" element={<EditTutorship />} />
            </Route>
            <Route element={<Auth allowedRoles={[Roles.ADMIN, Roles.TUTOR]} />}>
              <Route path=":tutorshipId/valorarEstudiante" element={<TutorToStudentRating />} />
            </Route>
            <Route element={<Auth allowedRoles={[Roles.ADMIN]} />}>
              <Route path="estadisticas" element={<TutorshipStatistics />} />
            </Route>
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar-clave" element={<PasswordRecovery />} />
        <Route path="/registrarse" element={<Register />} />
        <Route path="/recuperacion" element={<RegisterPassword />} />
        {/* TODO: Agregar página de error 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
